import { FaUserAlt, FaBriefcase, FaFileAlt } from 'react-icons/fa';

export const FormTabState = [
  {
    key: '1',
    icon: <FaUserAlt />,
    title: 'Personal Details',
    path: '/personal-details',
  },
  {
    key: '2',
    icon: <FaBriefcase />,
    title: 'Professional Details',
    path: '/professional-details',
  },
  {
    key: '3',
    icon: <FaFileAlt />,
    title: 'Other Details',
    path: '/other-details',
  },
];
