import { useContext, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import { Logout } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Navbar } from '../../../style/components/Nav.styled';
import { Logo } from '../../../imgs/imgs';
import { Img } from '../../../style/components/Graphics.styled';
import { MyContext } from '../../context/Context';
import { Flex } from '../../../style/utils/Flex.styled';

export const Nav = () => {
  const {
    userLogout,
    cPath,
    editApplication,
    appDetails,
    applicationDets,
  } = useContext(MyContext);
  const [userMenu, setUserMenu] = useState(null);
  const open = Boolean(userMenu);
  const handleUserMenu = (e) => {
    setUserMenu(e.currentTarget);
  };
  const handleClose = () => {
    setUserMenu(null);
  };

  useEffect(() => {
    appDetails();
  }, []);

  const appStatus = applicationDets.applicationStatus;
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    userLogout();
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  return (
    <Navbar>
      <Link to="/">
        <Img src={Logo.conversantech_logo} alt="logo" wid={'275px'} />
      </Link>

      <Flex>
        {cPath === '/application' && appStatus === 'rejected' && (
          <Button onClick={() => editApplication(appStatus)}>Edit</Button>
        )}
        <IconButton color="primary" onClick={handleUserMenu}>
          <AccountCircleIcon />
        </IconButton>
      </Flex>

      <Menu
        anchorEl={userMenu}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 2,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Dialog open={logoutDialogOpen} onClose={handleLogoutCancel}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          Are you sure you want to logout?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Navbar>
  );
};
