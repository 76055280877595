/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  AuthContainer,
  AUthForm,
  AuthInputs,
  AuthItem,
  AuthTypo,
} from './login.styled';
import { MyContext } from '../../context/Context';
import { Logo } from '../../../imgs/imgs';
import { Img } from '../../../style/components/Graphics.styled';
import loginValidationSchema from './loginValidationSchema';
import PasswordFiled from '../../components/form/formControl/PasswordField';
import TextField from '../../components/form/formControl/TextField';
import Button from '../../components/form/formControl/Button';
import LoadingSpinner from '../../components/form/Loading/LoadingSpinner';

const Login = () => {
  const {
    loginInitVal,
    userLogin,
  } = useContext(MyContext);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    await userLogin(values);
    setLoading(false);
  };

  return (
    <AuthContainer>
      <AuthItem bg>
        <Img src={Logo.ct_logo} alt="logo" />
      </AuthItem>
      <AuthItem>
        <Formik
          initialValues={loginInitVal}
          validationSchema={loginValidationSchema}
          onSubmit={(value) => handleSubmit(value)}
        >
          {(props) => {
            const {
              values,
              handleChange,
              touched,
              errors,
            } = props;
            return (
              <AUthForm>
                <AuthTypo>
                  <Typography className="login-title">Sign In</Typography>
                  <Typography className="login-subtitle">
                    Greetings once more, Please enter your credentials.
                  </Typography>
                </AuthTypo>
                <AuthInputs>
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    touched={touched.email}
                    errors={errors.email}
                    variant="outlined"
                  />
                  <PasswordFiled
                    id="outlined-adornment-password"
                    label="Password"
                    error={errors.password}
                    touched={touched.password}
                    mode={showPassword}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    iconOnClick={handleClickShowPassword}
                    variant="Outlined"
                  />
                  <Link to="/resest-password">Forgot your password?</Link>
                </AuthInputs>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  disabled={loading}
                >
                  {loading && <LoadingSpinner />}
                  Login
                </Button>
              </AUthForm>
            );
          }}
        </Formik>
      </AuthItem>
    </AuthContainer>
  );
};

export default Login;
