import React from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
// import PropTypes from 'prop-types';

const CardForMessage = (props) => {
  const {
    blockSpacing,
    customContentClass,
    typoVariant,
    typoColor,
    typoTextAlign,
    customActionClass,
    buttonVariant,
    buttonOnClick,
    icon,
    message,
  } = props;
  return (
    <Card sx={blockSpacing}>
      <CardActionArea>
        <CardContent className={customContentClass}>
          {icon}
          <Typography
            variant={typoVariant}
            color={typoColor}
            align={typoTextAlign}
          >
            {message}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={customActionClass}>
        <Button
          size="small"
          color="primary"
          variant={buttonVariant}
          onClick={buttonOnClick}
          id="navigationButtonId"
          name="navigationButtonName"
        >
          Back to Login
        </Button>
      </CardActions>
    </Card>
  );
};
// CardForMessage.propTypes = {
//   blockSpacing: PropTypes.shape({
//     maxWidth: PropTypes.number,
//     borderRadius: PropTypes.string,
//     padding: PropTypes.string,
//   }),
//   customContentClass: PropTypes.string,
//   typoVariant: PropTypes.string,
//   typoColor: PropTypes.string,
//   typoTextAlign: PropTypes.string,
//   customActionClass: PropTypes.string,
//   buttonVariant: PropTypes.string,
//   buttonOnClick: PropTypes.func,
//   icon: PropTypes.node,
//   message: PropTypes.string,
// };

// CardForMessage.defaultProps = {
//   blockSpacing: null,
//   customContentClass: null,
//   typoVariant: null,
//   typoColor: null,
//   typoTextAlign: null,
//   customActionClass: null,
//   buttonVariant: null,
//   buttonOnClick: () => {},
//   icon: null,
//   message: null,
// };
export default CardForMessage;
