// Header Style

import styled from 'styled-components';

export const HeaderStyled = styled.header`
  width: 100vw;
  height: auto;
  background-color: ${({ theme }) => theme.color.lightes};
  padding-block: 0.6rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: 999;
`;
