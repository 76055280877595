import { TextField } from '@mui/material';
import { Field } from 'formik';

export const InputField = ({
  type,
  label,
  name,
  value,
  handleChange,
  lock,
  err,
  helperTxt,
}) => {
  return (
    <TextField
      disabled={lock}
      type={type}
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
    />
  );
};

export const TextAreaField = ({
  label,
  name,
  value,
  handleChange,
  lock,
}) => {
  return (
    <TextField
      disabled={lock}
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
      multiline
      maxRows={2}
      fullWidth
    />
  );
};

// remove
export const InputProps = ({
  className,
  type,
  label,
  name,
}) => {
  return (
    <Field className={className} type={type} placeholder={label} name={name} />
  );
};

export const TextareaProps = ({ className, label, name }) => {
  return (
    <Field
      className={className}
      as="textarea"
      placeholder={label}
      name={name}
    />
  );
};
