import React, { useContext, useState } from 'react';
import { FieldArray } from 'formik';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '../../../../style/utils/Grid.styled';
import { CoursesDetailsInit } from '../statistics/otherDetailsInitialValues';
import { InputField } from '../../../components/form/formControl/FormInput';
import { FormWrap, FromContent } from '../../../../style/components/form/StyledForm.styled';
import { MyContext } from '../../../context/Context';
import showSnackbar from '../../../components/message/showSnackbar';
import { deleteCourses } from '../../../../api/apis';

const CoursesDetailsForm = ({ formController }) => {
  const { values, setFieldValue, handleChange } = formController;
  const { coursesDetails } = values;

  // State to control the visibility of the delete confirmation dialog
  const [coursDiaOpen, setCoursDiaOpen] = useState(false);

  // Extracting the getProfessionalDetails function from the context
  const [delCourseId, setDelCoueseId] = useState(null);

  // Extracting the getProfessionalDetails function from the context
  const { getCandidateOtherDetails } = useContext(MyContext);

  // Function to handle the initiation of the delete process
  const handleDelCourses = (id) => {
    // Set the ID of the experience to be deleted
    setDelCoueseId(id);
    // Open the delete confirmation dialog
    setCoursDiaOpen(true);
  };

  // Function to handle the confirmation of the delete action
  const handleDelEducationConfirm = async () => {
    // Call the API to delete
    const apiRes = await deleteCourses(delCourseId);
    // Destructure the API response
    const { success, message } = apiRes;

    if (success) {
      // If the deletion was successful
      // Show a success message
      showSnackbar(message, { variant: 'success' });
      // Close the delete confirmation dialog
      setCoursDiaOpen(false);
      // Refresh the professional details
      getCandidateOtherDetails();
    } else {
      // If the deletion failed
      // Show an error message
      showSnackbar(message, { variant: 'error' });
    }
  };

  // Function to handle the cancellation of the delete action
  const handleDelCoursesCancel = () => {
    // Close the delete confirmation dialog without deleting
    setCoursDiaOpen(false);
  };
  return (
    <FormWrap>
      <Typography variant="h6">Courses</Typography>
      <FieldArray name="coursesDetails">
        {(props) => {
          const { remove, push } = props;
          return (
            <Grid gap={'1rem'}>
              {coursesDetails !== undefined &&
                coursesDetails !== null &&
                coursesDetails.length > 0
                ? coursesDetails.map((add, index) => {
                  return (
                    <Card variant="outlined" key={index}>
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography gutterBottom>Details</Typography>
                        <IconButton
                          //  onClick={() => remove(index)}
                          onClick={() => {
                            const delId = coursesDetails[index]?.id;
                            if (delId) {
                              handleDelCourses(delId);
                            } else {
                              remove(index);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                      <CardContent>
                        <FromContent>
                          <InputField
                            label="Course Name"
                            name={`coursesDetails.${index}.courseName`}
                            value={values.coursesDetails[index].courseName}
                            handleChange={handleChange}
                          />
                          <InputField
                            label="Institute"
                            name={`coursesDetails.${index}.institute`}
                            value={values.coursesDetails[index].institute}
                            handleChange={handleChange}
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Completion date"
                              inputFormat="dd/MM/yyyy"
                              name={`coursesDetails.${index}.completionDate`}
                              value={
                                values.coursesDetails[index].completionDate
                              }
                              onChange={(val) => {
                                setFieldValue(
                                  `coursesDetails.${index}.completionDate`,
                                  val,
                                );
                              }}
                              renderInput={(params) => {
                                return <TextField {...params} />;
                              }}
                            />
                          </LocalizationProvider>
                        </FromContent>
                      </CardContent>
                    </Card>
                  );
                })
                : null}
              <Grid jc={'center'}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50%',
                    aspectRatio: '1/1',
                    minWidth: '0.2rem',
                    padding: '0.5rem',
                  }}
                >
                  <AddIcon onClick={() => push(CoursesDetailsInit)} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <Dialog open={coursDiaOpen} onClose={handleDelCoursesCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelCoursesCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelEducationConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </FormWrap>
  );
};

export default CoursesDetailsForm;
