import { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context/Context';
import { FormNav } from '../../components/nav/FormNav';
import PersonalDetailsForm from './forms/PersonalDetailsForm';
import AddressDetailsForm from './forms/AddressDetailsForm';
import FormFooterButtons from '../../components/footer/FormFooterButtons';
import personalDetailsValidationSchema from './statistics/personalDetailsValidationSchema';
import showSnackbar from '../../components/message/showSnackbar';

const PersonalDetailsPage = () => {
  const navigate = useNavigate();
  const {
    personalDets,
    savePersonalDetails,
    getPersonalDetails,
    loading,
  } = useContext(MyContext);

  useEffect(() => {
    const fetchData = async () => {
      await getPersonalDetails();
    };
    fetchData();
  }, []);

  return (
    <section>
      <FormNav />
      {!loading && (
        <Formik
          enableReinitialize={true}
          initialValues={personalDets}
          validationSchema={personalDetailsValidationSchema}
          onSubmit={async (value) => {
            const responseData = await savePersonalDetails(value);
            if (responseData.success) {
              if (responseData?.message) {
                showSnackbar(responseData.message, { variant: 'success' });
              }
              navigate(responseData.location);
            }
            if (!responseData.success) {
              showSnackbar(responseData.message, {
                variant: 'error',
              });
            }
          }}
        >
          {(props) => {
            return (
              <Form className="flex">
                <PersonalDetailsForm formController={props} />
                <AddressDetailsForm formController={props} />
                <FormFooterButtons />
              </Form>
            );
          }}
        </Formik>
      )}
    </section>
  );
};

export default PersonalDetailsPage;
