import {
  FromTabNumber,
  FromTabTitle,
  FromTabWrap,
  LinkTab,
  TabIcon,
} from '../../../style/components/Tab.styled';
import { Flex } from '../../../style/utils/Flex.styled';
import { FormTabState } from './statistics/FormTabState';
import { H4, H5 } from '../../../style/elements/Heading.style';

const FormTab = FormTabState;
export const FormNav = () => {
  return (
    <FromTabWrap>
      {FormTab.map((item) => {
        const {
          path,
          key,
          icon,
          title,
        } = item;
        return (
          <LinkTab to={path} key={key}>
            <FromTabNumber>
              <H5>{FormTab.indexOf(item) + 1}</H5>
            </FromTabNumber>
            <FromTabTitle>
              <TabIcon>{icon}</TabIcon>
              <H4 className="hide">{title}</H4>
            </FromTabTitle>
          </LinkTab>
        );
      })}
    </FromTabWrap>
  );
};
