const EducationCategoryOptions = [
  { value: 'deploma', label: 'Deploma' },
  { value: 'master', label: 'Master' },
  { value: 'bechlor', label: 'Bechlor' },
  { value: 'hsc', label: 'HSC' },
  { value: 'ssc', label: 'SSC' },
];

const EducationDegreeOptions = [
  {
    value: 'bachelors in computer application',
    label: 'bachelors in computer application',
  },
  {
    value: 'maser in computer application',
    label: 'maser in computer application',
  },
  {
    value: 'mcs',
    label: 'mcs',
  },
  {
    value: 'bachelor of commerce',
    label: 'bachelor of commerce',
  },
  {
    value: 'computer engineering',
    label: 'computer engineering',
  },
  {
    value: 'ssc',
    label: 'ssc',
  },
  {
    value: 'hsc',
    label: 'hsc',
  },
];
const RelationOptions = [
  { value: 'father', label: 'Father' },
  { value: 'mother', label: 'Mother' },
  { value: 'borther', label: 'Brother' },
  { value: 'sister', label: 'Sister' },
  { value: 'uncle', label: 'Uncle' },
  { value: 'aunty', label: 'Aunty' },
  { value: 'grandfather', label: 'Grandfather' },
  { value: 'grandmother', label: 'Grandmother' },
  { value: 'friend', label: 'Friend' },
];

const BloodTypeOptions = [
  { value: 'a+', label: 'A+' },
  { value: 'b+', label: 'B+' },
  { value: 'ab+', label: 'AB+' },
  { value: 'o+', label: 'O+' },
  { value: 'a-', label: 'A-' },
  { value: 'b-', label: 'B-' },
  { value: 'ab-', label: 'AB-' },
  { value: 'o-', label: 'O-' },
];

const LanguageOptions = ['gujarati', 'hindi', 'english'];
const HobbiesOptions = ['reading', 'writing', 'movies', 'games'];

export {
  EducationCategoryOptions,
  EducationDegreeOptions,
  RelationOptions,
  LanguageOptions,
  HobbiesOptions,
  BloodTypeOptions,
};
