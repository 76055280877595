// Imgs, Icons Styles

import styled from 'styled-components';

// Img style
export const Img = styled.img`
  width: ${({ wid }) => wid};
`;

export const PopupImage = styled.img`
  max-width: 420px;
  height: auto;
`;

// export ImagePopup

export const AvatarImg = styled.img`
  max-width: 180px;
  max-height: auto;
  border-radius: 50%;
  cursor: pointer;
`;

export const MediumImg = styled.img`
  max-width: 120px;
  max-height: auto;
  border-radius: 10px;
  cursor: pointer;
`;

// Icon Style
export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fz }) => fz};
`;
