import { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context/Context';
import { FormNav } from '../../components/nav/FormNav';
import ExperianceDetailsForm from './forms/ExperianceDetailsForm';
import PortfolioDetailsFrom from './forms/PortfolioDetailsForm';
import SkillDetailsForm from './forms/SkillDetailsForm';
import FormFooterButtons from '../../components/footer/FormFooterButtons';
import professionalDetailsValidationSchema from './statistics/professionalDetailsValidationSchema';
import showSnackbar from '../../components/message/showSnackbar';

const ProfessionalDetailsPage = () => {
  const navigate = useNavigate();
  const {
    professionalDets,
    saveProfessionalDetails,
    getProfessionalDetails,
  } = useContext(MyContext);
  useEffect(() => {
    getProfessionalDetails();
  }, []);
  return (
    <section>
      <FormNav />
      <Formik
        enableReinitialize={true}
        initialValues={professionalDets}
        onSubmit={async (value) => {
          const responseData = await saveProfessionalDetails(value);
          if (responseData.success) {
            if (responseData.message) {
              showSnackbar(responseData.message, {
                variant: 'success',
              });
            }
            navigate(responseData.location);
          }
          if (!responseData.success) {
            showSnackbar(responseData.message, {
              variant: 'error',
              autoHideDuration: 10000,
            });
          }
        }}
        validationSchema={professionalDetailsValidationSchema}
      >
        {(props) => {
          return (
            <Form className="flex">
              <ExperianceDetailsForm formController={props} />
              <SkillDetailsForm formController={props} />
              <PortfolioDetailsFrom formController={props} />
              <FormFooterButtons />
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ProfessionalDetailsPage;
