// Container for app

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.resolution.width};
  margin-inline: auto;
  padding-inline: 1rem;
`;
