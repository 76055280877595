import { Chip, Divider } from '@mui/material';

export const ViewDividerWithTitle = (props) => {
  const { title } = props;
  return (
    <span>
      <Divider>
        <Chip
          label={title}
          variant="fill"
          size="small"
          sx={{ backgroundColor: '#f5fcff', color: '#007DB8' }}
        />
      </Divider>
    </span>
  );
};
