import styled from 'styled-components';

const FormWrap = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  gap: 1rem;
  background-color: #fff;
  padding: 2rem 1rem;

  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const FromContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem 1rem;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

  .col-span-three {
    grid-column: 1 / span 3;
  }
  .col-span-two{
    grid-column:2 / span 2;
  }
`;

const FromContentTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem 1rem;

  .col-span-two {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const AdditionalDetContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 1rem;

  .col-span-two {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const PortFlDetContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 1rem;

  .col-span-two {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const FormContentInputDiv = styled.div`
  display:flex;
  flex-direction:column;
  gap:14px;
`;

export {
  FormWrap,
  FromContent,
  FromContentTwo,
  FormContentInputDiv,
  AdditionalDetContent,
  PortFlDetContent,
};
