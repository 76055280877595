import { Button } from '@mui/material';
import { useContext } from 'react';
import { ButtonWrapper } from '../../../style/components/Button.styled';
import { Flex } from '../../../style/utils/Flex.styled';
import { MyContext } from '../../context/Context';
import { Spineer } from '../../../style/components/Spinner.styled';

const FormFooterButtons = () => {
  const { cPath, spineersLoader } = useContext(MyContext);
  return (
    <ButtonWrapper
      disp="flex"
      jc={cPath === '/personal-details' ? 'flex-end' : 'space-between'}
      bg="white"
      pd="1rem"
    >
      {cPath === '/personal-details' ? null : (
        <Button disableElevation>Back</Button>
      )}
      <Flex gap="1rem">
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={spineersLoader}
        >
          {cPath === '/other-details' ? 'Submit' : 'Save & Next'}
          {spineersLoader && <Spineer />}
        </Button>
      </Flex>
    </ButtonWrapper>
  );
};

export default FormFooterButtons;
