import { Container } from '../../style/base/Container.styled';
import { HeaderStyled } from '../../style/layout/Header.styled';
import { Nav } from '../components/nav/Nav';

// Header Layout
export const Header = () => {
  return (
    <HeaderStyled>
      <Container>
        <Nav />
      </Container>
    </HeaderStyled>
  );
};
