// import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import theme from '../style/theme/Theme.style';
import { GlobalStyles } from '../style/base/Global.styled';
import Error from './page/error/Error';
import Login from './page/login/Login';
import { Header } from './layout/Header';
import { Main } from './layout/Main';
import ExaminingApplication from './page/application/ExaminingApplication';
import EmailScreen from './page/PasswordManager/EmailScreen';
import NewPassWord from './page/PasswordManager/NewPassWord';

const candidate = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Routes>
          <Route path="/*" element={candidate()} />
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<Error />} />
          <Route
            path="/application-admin-view"
            element={<ExaminingApplication />}
          />
          <Route path="/resest-password" element={<EmailScreen />} />
          <Route path="/add-new-password" element={<NewPassWord />} />
        </Routes>
      </SnackbarProvider>
      <GlobalStyles />
    </ThemeProvider>
  );
};
