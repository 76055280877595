import React, { useContext, useState } from 'react';
import { FieldArray } from 'formik';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { has, isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '../../../../style/utils/Grid.styled';
import {
  EducationCategoryOptions,
  EducationDegreeOptions,
} from '../statistics/otherDetailsSelectOptions';
import { EducationDetailsInit } from '../statistics/otherDetailsInitialValues';
import { FormContentInputDiv, FormWrap, FromContent } from '../../../../style/components/form/StyledForm.styled';
import ErrorMessage from '../../../components/message/ErrorMessage';
import CustomTextField from '../../../components/form/formControl/TextField';
import showSnackbar from '../../../components/message/showSnackbar';
import { deleteEducation } from '../../../../api/apis';
import { MyContext } from '../../../context/Context';

const EducationDetailsForm = () => {
  // State to control the visibility of the delete confirmation dialog
  const [eduDelDiaOpen, setEduDelDiaOpen] = useState(false);

  // State to store the ID of the experience to be deleted
  const [delEduId, setEduDelId] = useState(null);

  // Extracting the getProfessionalDetails function from the context
  const { getCandidateOtherDetails } = useContext(MyContext);

  // Function to handle the initiation of the delete process
  const handleDelEducation = (id) => {
    // Set the ID of the experience to be deleted
    setEduDelId(id);
    // Open the delete confirmation dialog
    setEduDelDiaOpen(true);
  };

  // Function to handle the confirmation of the delete action
  const handleDelEducationConfirm = async () => {
    // Call the API to delete
    const apiRes = await deleteEducation(delEduId);
    // Destructure the API response
    const { success, message } = apiRes;

    if (success) {
      // If the deletion was successful
      // Show a success message
      showSnackbar(message, { variant: 'success' });
      // Close the delete confirmation dialog
      setEduDelDiaOpen(false);
      // Refresh the professional details
      getCandidateOtherDetails();
    } else {
      // If the deletion failed
      // Show an error message
      showSnackbar(message, { variant: 'error' });
    }
  };

  // Function to handle the cancellation of the delete action
  const handleDelEduCancel = () => {
    // Close the delete confirmation dialog without deleting
    setEduDelDiaOpen(false);
  };

  return (
    <FormWrap>
      <Typography variant="h6">Education Details</Typography>
      <FieldArray name="educationDetails">
        {(props) => {
          const { remove, push, form } = props;
          const {
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
          } = form;
          const { educationDetails } = values;
          return (
            <Grid gap={'1rem'}>
              {educationDetails !== undefined &&
                educationDetails !== null &&
                educationDetails.length > 0
                ? educationDetails.map((add, index) => {
                  return (
                    <Card variant="outlined" key={index}>
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography gutterBottom>Details</Typography>
                        {educationDetails.length !== 1 && (
                          <IconButton
                            onClick={() => {
                              const delId = educationDetails[index]?.id;
                              if (delId) {
                                handleDelEducation(delId);
                              } else {
                                remove(index);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </CardActions>
                      <CardContent>
                        <FromContent>
                          <FormControl>
                            <InputLabel
                              error={
                                !isEmpty(touched) && !isEmpty(errors) &&
                                has(errors, 'educationDetails') &&
                                !isEmpty(touched.educationDetails[index]) &&
                                touched.educationDetails[index].category &&
                                !isEmpty(errors.educationDetails[index]) &&
                                Boolean(errors.educationDetails[index].category)
                              }
                            >
                              Category
                            </InputLabel>
                            <FormContentInputDiv>
                              <Select
                                label="Category"
                                name={`educationDetails.${index}.category`}
                                value={values.educationDetails[index].category}
                                onChange={handleChange}
                                error={
                                  !isEmpty(touched) && !isEmpty(errors) &&
                                  has(errors, 'educationDetails') &&
                                  !isEmpty(touched.educationDetails[index]) &&
                                  touched.educationDetails[index].category &&
                                  !isEmpty(errors.educationDetails[index]) &&
                                  Boolean(errors.educationDetails[index].category)
                                }
                              >
                                {EducationCategoryOptions.map((category, i) => {
                                  const { value, label } = category;
                                  return (
                                    <MenuItem key={i} value={value}>{label}</MenuItem>
                                  );
                                })}
                              </Select>
                              <ErrorMessage
                                message={
                                  !isEmpty(touched) && !isEmpty(errors) &&
                                  has(errors, 'educationDetails') &&
                                  !isEmpty(touched.educationDetails[index]) &&
                                  touched.educationDetails[index].category &&
                                  !isEmpty(errors.educationDetails[index]) &&
                                  errors.educationDetails[index].category

                                }
                              />
                            </FormContentInputDiv>
                            {/* {
                              !isEmpty(touched) && !isEmpty(errors) &&
                              has(errors, 'educationDetails') &&
                              !isEmpty(touched.educationDetails[index]) &&
                              touched.educationDetails[index].category &&
                              !isEmpty(errors.educationDetails[index]) &&
                              (
                                <FormHelperText error>
                                  {errors.educationDetails[index].category}
                                </FormHelperText>
                              )
                            } */}

                          </FormControl>
                          <FormControl>
                            <InputLabel
                              error={
                                !isEmpty(touched) && !isEmpty(errors) &&
                                has(errors, 'educationDetails') &&
                                !isEmpty(touched.educationDetails[index]) &&
                                touched.educationDetails[index].degreeName &&
                                !isEmpty(errors.educationDetails[index]) &&
                                Boolean(errors.educationDetails[index].degreeName)
                              }
                            >
                              Degree
                            </InputLabel>
                            <FormContentInputDiv>
                              <Select
                                label="Degree"
                                name={`educationDetails.${index}.degreeName`}
                                value={values.educationDetails[index].degreeName}
                                onChange={handleChange}
                                error={
                                  !isEmpty(touched) && !isEmpty(errors) &&
                                  has(errors, 'educationDetails') &&
                                  !isEmpty(touched.educationDetails[index]) &&
                                  touched.educationDetails[index].degreeName &&
                                  !isEmpty(errors.educationDetails[index]) &&
                                  Boolean(errors.educationDetails[index].degreeName)
                                }
                              >
                                {EducationDegreeOptions.map((degree, i) => {
                                  const { value, label } = degree;
                                  return (
                                    <MenuItem key={i} value={value}>{label}</MenuItem>
                                  );
                                })}
                              </Select>
                              {/* {
                              !isEmpty(touched) && !isEmpty(errors) &&
                              has(errors, 'educationDetails') &&
                              !isEmpty(touched.educationDetails[index]) &&
                              touched.educationDetails[index].degreeName &&
                              !isEmpty(errors.educationDetails[index]) &&
                              (
                                <FormHelperText error>
                                  {errors.educationDetails[index].degreeName}
                                </FormHelperText>
                              )
                            } */}
                              <ErrorMessage
                                message={
                                  !isEmpty(touched) && !isEmpty(errors) &&
                                  has(errors, 'educationDetails') &&
                                  !isEmpty(touched.educationDetails[index]) &&
                                  touched.educationDetails[index].degreeName &&
                                  !isEmpty(errors.educationDetails[index]) &&
                                  errors.educationDetails[index].degreeName
                                }
                              />
                            </FormContentInputDiv>
                          </FormControl>
                          <CustomTextField
                            label="Institute"
                            name={`educationDetails.${index}.institute`}
                            value={values.educationDetails[index].institute}
                            onChange={handleChange}
                            errors={
                              has(errors, `educationDetails.${index}.institute`) &&
                                touched.educationDetails?.[index]?.institute ?
                                errors.educationDetails[index].institute : ''
                            }
                            touched={touched.educationDetails?.[index]?.institute}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="Board/University"
                            name={`educationDetails.${index}.boardOrUniversity`}
                            value={values.educationDetails[index].boardOrUniversity}
                            onChange={handleChange}
                            errors={
                              has(errors, `educationDetails.${index}.boardOrUniversity`) &&
                                touched.educationDetails?.[index]?.boardOrUniversity ?
                                errors.educationDetails[index].boardOrUniversity : ''
                            }
                            touched={touched.educationDetails?.[index]?.boardOrUniversity}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="GPA/ Percentage"
                            name={`educationDetails.${index}.gpaOrPercentage`}
                            value={values.educationDetails[index].gpaOrPercentage}
                            onChange={handleChange}
                            errors={
                              has(errors, `educationDetails.${index}.gpaOrPercentage`) &&
                                touched.educationDetails?.[index]?.gpaOrPercentage ?
                                errors.educationDetails[index].gpaOrPercentage : ''
                            }
                            touched={touched.educationDetails?.[index]?.gpaOrPercentage}
                            variant="outlined"
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormContentInputDiv>
                              <DatePicker
                                label="Passig Year"
                                inputFormat="dd/MM/yyyy"
                                name={`educationDetails.${index}.passingYear`}
                                value={values.educationDetails[index].passingYear}
                                onChange={(val) => {
                                  setFieldValue(`educationDetails.${index}.passingYear`, val);
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      error={
                                        !isEmpty(touched) && !isEmpty(errors) &&
                                        has(errors, 'educationDetails') &&
                                        !isEmpty(touched.educationDetails[index]) &&
                                        touched.educationDetails[index].passingYear &&
                                        !isEmpty(errors.educationDetails[index]) &&
                                        Boolean(errors.educationDetails[index].passingYear)
                                      }
                                    />
                                  );
                                }}
                              />
                              <ErrorMessage
                                message={
                                  !isEmpty(touched) && !isEmpty(errors) &&
                                  has(errors, 'educationDetails') &&
                                  !isEmpty(touched.educationDetails[index]) &&
                                  touched.educationDetails[index].passingYear &&
                                  !isEmpty(errors.educationDetails[index]) &&
                                  errors.educationDetails[index].passingYear
                                }
                              />
                            </FormContentInputDiv>
                          </LocalizationProvider>
                        </FromContent>
                      </CardContent>
                    </Card>

                  );
                })
                : null}
              <Grid jc={'center'}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50%',
                    aspectRatio: '1/1',
                    minWidth: '0.2rem',
                    padding: '0.5rem',
                  }}
                >
                  <AddIcon onClick={() => push(EducationDetailsInit)} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <Dialog open={eduDelDiaOpen} onClose={handleDelEduCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelEduCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelEducationConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </FormWrap>
  );
};

export default EducationDetailsForm;
