import styled from 'styled-components';
import { FaSortDown, FaTrash } from 'react-icons/fa';

// Default Button Style
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => gap};
  width: auto;
  min-width: ${({ mxwd }) => mxwd || '100px'};
  outline: none;
  background: none;
  border: none;
  background-color: ${({ theme, btn }) => (btn !== 'secondary' ? theme.color.primary : theme.color.lightes)};
  color: ${({ theme, btn }) => (btn !== 'secondary' ? theme.color.lightes : theme.color.primary)};
  font-size: 1.12rem;
  padding: 0.6rem;
  border-radius: 50px;
  transition: ${({ theme }) => theme.zeroTwo};
  border: 2px solid ${({ theme }) => theme.color.primary};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, btn }) => (btn !== 'secondary' ? theme.color.lightes : theme.color.primary)};
    color: ${({ theme, btn }) => (btn !== 'secondary' ? theme.color.primary : theme.color.lightes)};
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

// Add Button Style
export const Add = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ ratio, wd }) => ratio || wd};
  height: ${({ ratio, hg }) => ratio || hg};
  outline: none;
  background: none;
  border: none;
  background-color: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primary};
  border-radius: ${({ radius }) => radius || '100%'} ;
  padding: ${({ pd }) => pd};
  transition: ${({ theme }) => theme.zeroTwo};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.lightes};
  }
`;

// Remove Button Style
export const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ ratio, wd }) => ratio || wd};
  height: ${({ ratio, hg }) => ratio || hg};
  outline: none;
  background: none;
  border: none;
  background-color: ${({ theme }) => theme.color.errorLight};
  color: ${({ theme }) => theme.color.errorDark};
  border-radius: 100%;
  transition: ${({ theme }) => theme.zeroTwo};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.errorDark};
    color: ${({ theme }) => theme.color.lightes};
  }
`;

export const Delete = styled(FaTrash)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.color.errorDark};
  transition: 0.1s ease-in;

  &:hover {
    color: ${({ theme }) => theme.color.errorDark};
    transform: scale(1.2);
  }
`;

export const Show = styled.a`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: ${({ disp }) => disp};
  gap: ${({ gap }) => gap};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bg }) => bg};
  padding: ${({ pd }) => pd};
  margin-block: ${({ mgBlock }) => mgBlock};
  border-radius: 10px;
  font-size: ${({ fz }) => fz};
`;

export const AccordionBtn = styled(FaSortDown)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`;
