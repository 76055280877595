import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const LoadingSpinner = ({
  size = 22,
  color = 'inherit',
  thickness = 3.6,
}) => {
  return (
    <CircularProgress
      size={size}
      color={color}
      thickness={thickness}
      style={{ marginRight: '5px' }}
    />
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  thickness: PropTypes.number,
};

export default LoadingSpinner;
