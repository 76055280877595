/* eslint-disable */
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const showSnackbar = (message, options = {}) => {
  const { variant = 'default', autoHideDuration = 5000, dismissable = true } = options;
  enqueueSnackbar(message, {
    variant,
    autoHideDuration,
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    action: (key) => (
      dismissable && <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
        <CloseIcon color='inherit' />
      </IconButton>
    )
  });
};

export default showSnackbar;