import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray } from 'formik';
import { ColSpan, Grid } from '../../../../style/utils/Grid.styled';
import { InputField, TextAreaField } from '../../../components/form/formControl/FormInput';
import { PortfolioDetailsInit } from '../statistics/professionalDetailsInitialValues';
import { FormWrap, PortFlDetContent } from '../../../../style/components/form/StyledForm.styled';
import { MyContext } from '../../../context/Context';
import { deletePortFolio } from '../../../../api/apis';
import showSnackbar from '../../../components/message/showSnackbar';

const PortfolioDetailsFrom = ({ formController }) => {
  const { values, handleChange } = formController;
  const { portfolioDetails } = values;
  // State to control the visibility of the delete confirmation dialog
  const [portfoDelDiaOpen, setPortFoDelDiaOpen] = useState(false);

  // State to store the ID of the experience to be deleted
  const [delPortFoId, setPortFoId] = useState(null);

  // Extracting the getProfessionalDetails function from the context
  const { getProfessionalDetails } = useContext(MyContext);

  // Function to handle the initiation of the delete process
  const handleDelPortFolio = (id) => {
    // Set the ID of the experience to be deleted
    setPortFoId(id);
    // Open the delete confirmation dialog
    setPortFoDelDiaOpen(true);
  };

  // Function to handle the confirmation of the delete action
  const handleDelPortFolioConfirm = async () => {
    // Call the API to delete
    const apiRes = await deletePortFolio(delPortFoId);
    // Destructure the API response
    const { success, message } = apiRes;

    if (success) {
      // If the deletion was successful
      // Show a success message
      showSnackbar(message, { variant: 'success' });
      // Close the delete confirmation dialog
      setPortFoDelDiaOpen(false);
      // Refresh the professional details
      getProfessionalDetails();
    } else {
      // If the deletion failed
      // Show an error message
      showSnackbar(message, { variant: 'error' });
    }
  };

  // Function to handle the cancellation of the delete action
  const handleDelPortFolioCancel = () => {
    // Close the delete confirmation dialog without deleting
    setPortFoDelDiaOpen(false);
  };

  return (
    <FormWrap>
      <Typography variant="h6">Portfolio</Typography>
      <FieldArray name="portfolioDetails">
        {(props) => {
          const { remove, push } = props;
          return (
            <Grid gap={'1rem'}>
              {portfolioDetails !== undefined &&
                portfolioDetails !== null &&
                portfolioDetails.length > 0
                ? portfolioDetails.map((add, index) => {
                  return (
                    <Card variant="outlined" key={index}>
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography gutterBottom>Details </Typography>
                        <IconButton
                          aria-label="delete"
                          // onClick={() => remove(index)}
                          onClick={() => {
                            const portFoId = portfolioDetails[index]?.id;
                            if (portFoId) {
                              handleDelPortFolio(portFoId);
                            } else {
                              remove(index);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                      <CardContent>
                        <PortFlDetContent>
                          <InputField
                            label="Title"
                            name={`portfolioDetails.${index}.title`}
                            value={values.portfolioDetails[index].title}
                            handleChange={handleChange}
                          />
                          <InputField
                            label="Link"
                            name={`portfolioDetails.${index}.link`}
                            value={values.portfolioDetails[index].link}
                            handleChange={handleChange}
                          />
                          <ColSpan colSpan={'1 / span 2'}>
                            <TextAreaField
                              label="Description"
                              name={`portfolioDetails.${index}.description`}
                              value={
                                values.portfolioDetails[index].description
                              }
                              handleChange={handleChange}
                            />
                          </ColSpan>
                        </PortFlDetContent>
                      </CardContent>
                    </Card>
                  );
                })
                : null}
              <Grid jc={'center'}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50%',
                    aspectRatio: '1/1',
                    minWidth: '0.2rem',
                    padding: '0.5rem',
                  }}
                >
                  <AddIcon onClick={() => push(PortfolioDetailsInit)} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <Dialog open={portfoDelDiaOpen} onClose={handleDelPortFolioCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelPortFolioCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelPortFolioConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </FormWrap>
  );
};

export default PortfolioDetailsFrom;
