import { Route, Routes } from 'react-router-dom';
import { Container } from '../../style/base/Container.styled';
import Home from '../page/home/Home';
import PersonalDetailsPage from '../page/personal_details/PersonalDetailsPage';
import ProfessionalDetailsPage from '../page/professional_details/ProfessionalDetailsPage';
import OtherDetailsPage from '../page/other_details/OtherDetailsPage';
import Application from '../page/application/Application';

export const Main = () => {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/personal-details" element={<PersonalDetailsPage />} />
        <Route
          path="/professional-details"
          element={<ProfessionalDetailsPage />}
        />
        <Route path="/other-details" element={<OtherDetailsPage />} />
        <Route path="/application" element={<Application />} />
      </Routes>
    </Container>
  );
};
