import { Button as MUIButton } from '@mui/material';

const Button = (props) => {
  const {
    children = '',
    id = '',
    type = 'submit',
    variant = 'contained',
    disabled = false,
  } = props;
  return (
    <MUIButton
      id={id}
      type={type}
      variant={variant}
      disableElevation
      disabled={disabled}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
