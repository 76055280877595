import { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { MyContext } from '../../context/Context';
import consoleLog from '../../../func/consoleLog';
import { FormNav } from '../../components/nav/FormNav';
import BankDetailsForm from './forms/BankDetailsForm';
import EducationDetailsForm from './forms/EducationDetailsForm';
import CoursesDetailsForm from './forms/CoursesDetailsForm';
import EmergencyContactDetailsForm from './forms/EmergencyContactDetailsForm';
import FormFooterButtons from '../../components/footer/FormFooterButtons';
import AdditionalDetailsForm from './forms/AdditionalDetailsForm';
import otherDetailsValidationSchema from './statistics/otherDetailsValidationSchema';
import showSnackbar from '../../components/message/showSnackbar';

const OtherDetailsPage = () => {
  const {
    appIds,
    getOnBoardAppDets,
    otherDets,
    saveOtherDetails,
    getCandidateOtherDetails,
    setSpineersLoader,
  } = useContext(MyContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    // getOnBoardAppDets(appIds);
    getCandidateOtherDetails();
  }, []);
  return (
    <section>
      <FormNav />
      <Formik
        enableReinitialize={true}
        initialValues={otherDets}
        onSubmit={async (value) => {
          setSpineersLoader(true);
          const saveOtherDetailsResponse = await saveOtherDetails(value);
          if (saveOtherDetailsResponse.success) {
            if (saveOtherDetailsResponse.message) {
              showSnackbar(saveOtherDetailsResponse.message, {
                variant: 'success',
              });
            }
          } else {
            showSnackbar(saveOtherDetailsResponse.message, {
              variant: 'error',
            });
          }
        }}
        validationSchema={otherDetailsValidationSchema}
      >
        {(props) => {
          return (
            <Form className="flex">
              <BankDetailsForm formController={props} />
              <EducationDetailsForm formController={props} />
              <CoursesDetailsForm formController={props} />
              <EmergencyContactDetailsForm formController={props} />
              <AdditionalDetailsForm formController={props} />
              <FormFooterButtons />
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default OtherDetailsPage;
