import styled from 'styled-components';
import { Flex } from '../../utils/Flex.styled';

export const TagWrapperr = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ dir }) => dir};
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
export const TagAddContainer = styled(Flex)`
  flex-direction: column;
  gap: 0.5rem;
`;
export const TagAdd = styled(Flex)`
  align-items: center;
  gap: 1rem;
`;
// Tag Btn
export const TagBtnWrap = styled(Flex)`
  background-color: ${({ theme }) => theme.color.primaryLight};
  padding: ${({ pd }) => pd};
  border-radius: 100%;
`;
export const TagAddBtn = styled(Flex)`
  background-color: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primary};
  padding: 0.4rem;
  border-radius: 100%;
  cursor: pointer;
`;
export const TagRemoveBtn = styled(Flex)`
  background-color: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primary};
  font-size: 0.9rem;
  border-radius: 100%;
  cursor: pointer;
`;
export const TagInput = styled.input`
  width: ${({ wd }) => wd};
  outline: none;
  border: none;
  background: white;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.color.lightDark};
  border-radius: ${({ theme }) => theme.radius};
`;
export const TagWrap = styled(Flex)`
  flex-wrap: wrap;
  gap: 0.5rem;
`;
export const Tag = styled(Flex)`
  min-width: 80px;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.2rem 0.5rem;
  background-color: ${({ theme }) => theme.color.primaryLight};
  border: 2px solid ${({ theme }) => theme.color.primary};
  border-radius: 50px;
  p {
    color: ${({ theme }) => theme.color.primary};
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeight.w3};
  }
`;
export const TagError = styled.div`
  width: ${({ wd }) => wd};
  background-color: ${({ theme }) => theme.color.errorLight};
  color: ${({ theme }) => theme.color.errorDark};
  border-radius: ${({ theme }) => theme.radius};
  padding: 0.2rem 0.4rem;
  font-size: 14px;
  font-weight: 600;
`;
