// Imported React Componets as a props
import styled from 'styled-components';
// import { CgAsterisk } from 'react-icons/cg';
import { RiAsterisk } from 'react-icons/ri';
// import { BsAsterisk } from 'react-icons/bs';
import { LabelProps } from '../../../template/components/form/formControl/FormLabel';

// Lable Style
export const Label = styled(LabelProps)`
  font-size: calc(16px + ${({ fsz }) => fsz});
  font-weight: ${({ fw }) => fw};
  color: ${({ theme }) => theme.color.darkest};
`;
export const Required = styled(RiAsterisk)`
  /* color: ${({ theme }) => theme.color.primary}; */
  font-size: 0.7rem;
  color: red;
`;

// From lable and other field wrraper
export const FormGroup = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '0.5rem'};
  flex-direction: column;
  grid-column: ${({ colSpan }) => colSpan};
  grid-row: ${({ rowSpan }) => rowSpan};
  justify-content: ${({ jc }) => jc};
  margin-block: ${({ mb }) => mb};
`;
