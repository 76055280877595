// H1 to H6 heading tags styles

import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.h1};
  font-weight: ${({ fw }) => fw};
`;
export const H2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.h2};
  font-weight: ${({ fw }) => fw};
`;
export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.h3};
  font-weight: ${({ fw }) => fw};
`;
export const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.h4};
  font-weight: ${({ fw }) => fw};
`;
export const H5 = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.h5};
  font-weight: ${({ fw }) => fw};
  grid-column: ${({ colSpan }) => colSpan};
  text-align: ${({ tal }) => tal};
`;
export const H6 = styled.h6`
  font-size: ${({ theme }) => theme.fontSize.h6};
  font-weight: ${({ fw }) => fw};
`;

export const TitleWithHorizontalLines = styled.h5`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  font-size: ${({ theme }) => theme.fontSize.h5};
  font-weight: ${({ fw }) => fw};
  text-align: center;
  overflow: hidden;
  grid-column: ${({ colSpan }) => colSpan};

  &::before,
  &::after {
    content: '';
    width: 20%;
    height: 3px;
    border-bottom: 3px solid ${({ theme }) => theme.color.primary};
  }
`;
