// Dynamik Flex component

import styled from 'styled-components';

export const Flex = styled.div`
  width: ${({ wd }) => wd};
  display: flex;
  justify-content: ${({ jc }) => jc};
  align-items: ${({ ai }) => ai};
  gap: ${({ gap }) => gap};
  flex-direction: ${({ dir }) => dir};
  margin-block: ${({ mb }) => mb};
  background-color: ${({ bg }) => bg};
  padding: ${({ pd }) => pd};
`;

/*
do not use FlexWrapper in Container because of view width
use FlexWrapper out of Container
*/
export const FlexWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
