import React from 'react';
import { Typography } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ViewDividerWithTitle } from '../controls/ViewDividerWithTitle';
import { ViewField } from '../controls/ViewField';
import { ImagePopup } from '../../../components/img/ImagePopup';
import {
  ViewDetailsContentItems,
  ViewDetailsItemTwo,
  ViewDetailsItemWithChips,
  ViewDetailsTitleWithIcon,
  ViewDetailsWrap,
} from '../../../../style/components/ApplicationView.styled';
import { ViewChip } from '../controls/ViewChip';

export const OtherDetailsView = ({ details }) => {
  const {
    educationDetails,
    coursesDetails,
    bankDetails,
    emergencyContactDetails,
    personalDetails,
  } = details;

  const changeDateFormat = (props) => {
    const propDate = props !== undefined && props !== '' ? props : Date();
    const d = new Date(propDate);
    const date = d.toISOString().substring(0, 10);

    return date;
  };

  return (
    <ViewDetailsWrap>
      {/* 1) Education details  */}
      {!isEmpty(educationDetails) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Education Details'} />

          {educationDetails.map((eduDets, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Education Details ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Category'}
                    value={educationDetails[index].category}
                  />
                  <ViewField
                    name={'Degree name'}
                    value={educationDetails[index].degreeName}
                  />
                  <ViewField
                    name={'Board or University'}
                    value={educationDetails[index].boardOrUniversity}
                  />
                  <ViewField
                    name={'Institute'}
                    value={educationDetails[index].institute}
                  />
                  <ViewField
                    name={'GPA or Percentage'}
                    value={educationDetails[index].gpaOrPercentage}
                  />
                  <ViewField
                    name={'Passing Year'}
                    value={
                      educationDetails[index].passingYear &&
                      moment(
                        changeDateFormat(educationDetails[index].passingYear),
                      ).format('LL')
                    }
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}

      {/* 2) course details  */}
      {!isEmpty(coursesDetails) && !isEmpty(coursesDetails[0].courseName) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'other coursesDetails'} />

          {coursesDetails.map((course, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Course ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Passing Course name'}
                    value={coursesDetails[index].courseName}
                  />
                  <ViewField
                    name={'Institute'}
                    value={coursesDetails[index].institute}
                  />
                  <ViewField
                    name={'date'}
                    value={
                      coursesDetails[index].completionDate &&
                      moment(coursesDetails[index].completionDate).format('LL')
                    }
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}

      {/* 3) Bank details */}
      {!isEmpty(bankDetails) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Bank Details'} />
          <ViewDetailsItemTwo>
            <ViewField name={'Bank name'} value={bankDetails.bankName} />
            <ViewField name={'Bank branch'} value={bankDetails.branch} />
            <ViewField
              name={'Account holder name'}
              value={bankDetails.accountHolder}
            />
            <ViewField name={'Account No.'} value={bankDetails.accountNo} />
            <ViewField name={'IFSC No.'} value={bankDetails.ifscNo} />
            <ImagePopup
              img={bankDetails.document}
              alt={'bank document'}
              skeletonRatio={120}
              skeletonVariant="rectangular"
              css="pos-end-row-span"
            />
          </ViewDetailsItemTwo>
        </ViewDetailsContentItems>
      ) : null}
      {/* 4) Emergency Details */}
      {!isEmpty(emergencyContactDetails) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Emergency Details'} />
          {emergencyContactDetails.map((emrgCnt, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Emergency Contact ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Full name.'}
                    value={emergencyContactDetails[index].fullname}
                  />
                  <ViewField
                    name={'Relation'}
                    value={emergencyContactDetails[index].relation}
                  />
                  <ViewField
                    name={'Contact No.'}
                    value={emergencyContactDetails[index].contactNo}
                  />
                  <ViewField
                    name={'City'}
                    value={emergencyContactDetails[index].city}
                  />
                  <ViewField
                    name={'Note'}
                    value={emergencyContactDetails[index].note}
                    css="col-span-four"
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}
      <ViewDetailsContentItems>
        <ViewDividerWithTitle title={'Other Details'} />
        <ViewDetailsItemWithChips>
          <ViewChip name={'Languages'} value={personalDetails.languages} />
          <ViewChip name={'Hobbies'} value={personalDetails.hobbies} />
        </ViewDetailsItemWithChips>
        <ViewDetailsItemTwo>
          <ViewField
            name={'Marital Status'}
            value={personalDetails.maritalStatus}
          />
          {personalDetails.maritalStatus === 'married' && (
            <>
              <ViewField
                name={'Partner Name'}
                value={personalDetails.partnerName}
              />
              <ViewField
                name={'Marriage Anniversary'}
                value={personalDetails.marriageAnniversary}
              />
            </>
          )}
          <ViewField name={'Blood Type'} value={personalDetails.bloodType} />
        </ViewDetailsItemTwo>
      </ViewDetailsContentItems>
    </ViewDetailsWrap>
  );
};
