import * as Yup from 'yup';

const professionalDetailsValidationSchema = Yup.object({
  personalDetails: Yup.object({
    experianceStatus: Yup.string().required('experiance status is required'),
  }),
  experianceDetails: Yup.array().when('personalDetails.experianceStatus', {
    is: 'experienced',
    then: Yup.array(
      Yup.object().shape({
        companyName: Yup.string().required('Company name is required'),
        jobTitle: Yup.string().required('Job title is required'),
        yearOfExperiance: Yup.string().required('Year of experiance is required'),
        city: Yup.string().required('City is required'),
        joinningDate: Yup.date().required('Joinning date is required').nullable(),
        tillDate: Yup.date().required('Till date is required').nullable(),
      }),
    ),
  }),
  skillSet: Yup.object({
    skills: Yup.array()
      .min(3, 'More then 3 Primary skills required')
      .required(),
    tools: Yup.array()
      .min(1, 'More then 1 secondary tool required')
      .required(),
  }),
});
export default professionalDetailsValidationSchema;
