import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  FormWrap,
  AdditionalDetContent,
} from '../../../../style/components/form/StyledForm.styled';
import { InputField } from '../../../components/form/formControl/FormInput';
import {
  LanguageOptions,
  HobbiesOptions,
  BloodTypeOptions,
} from '../statistics/otherDetailsSelectOptions';

const AdditionalDetailsForm = ({ formController }) => {
  const { values, handleChange, setFieldValue } = formController;
  return (
    <FormWrap>
      <Typography variant="h6">Additional Details</Typography>
      <AdditionalDetContent>
        <FormControl>
          <InputLabel>Languages</InputLabel>
          <Select
            multiple
            name="personalDetails.languages"
            value={values.personalDetails.languages}
            onChange={handleChange}
            input={<OutlinedInput label="Languages" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {LanguageOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Hobbies</InputLabel>
          <Select
            multiple
            name="personalDetails.hobbies"
            value={values.personalDetails.hobbies}
            onChange={handleChange}
            input={<OutlinedInput label="Hobbies" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {HobbiesOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Blood Type</InputLabel>
          <Select
            label="bloodType"
            name="personalDetails.bloodType"
            value={values.personalDetails.bloodType}
            onChange={handleChange}
          >
            {BloodTypeOptions.map((blood, i) => {
              const { value, label } = blood;
              return (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Divider
          className="col-span-two"
          textAlign="left"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          Marital Details
        </Divider>
        <AdditionalDetContent className="col-span-two">
          <FormControl sx={{ gridColumn: '1 / span 2' }}>
            <FormLabel>Marital Status</FormLabel>
            <RadioGroup
              name="personalDetails.maritalStatus"
              value={values.personalDetails.maritalStatus}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="single"
                control={<Radio />}
                label="Single"
              />
              <FormControlLabel
                value="married"
                control={<Radio />}
                label="Married"
              />
            </RadioGroup>
          </FormControl>
          {values.personalDetails.maritalStatus === 'married' ? (
            <>
              <InputField
                label="Partner name"
                name="personalDetails.partnerName"
                value={values.personalDetails.partnerName}
                handleChange={handleChange}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Marriage Anniversary"
                  inputFormat="dd/MM/yyyy"
                  name={'personalDetails.marriageAnniversary'}
                  value={values.personalDetails.marriageAnniversary}
                  onChange={(val) => {
                    setFieldValue('personalDetails.marriageAnniversary', val);
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              </LocalizationProvider>
            </>
          ) : null}
        </AdditionalDetContent>
      </AdditionalDetContent>
    </FormWrap>
  );
};

export default AdditionalDetailsForm;
