import styled from 'styled-components';

const ViewDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: justify;
`;

const ViewDetailsContentWithImg = styled.div`
  display: grid;
  /* grid-template-columns: 30% auto; */
  grid-template-columns: ${({ reverse }) => (reverse ? 'auto 30%' : '30% auto')};
  gap: 1rem;
  align-items: start;

  .img-mw {
    max-width: 160px;
  }

  .lol {
    background-color: red;
    padding: 1rem;
  }

  & > img {
    max-width: 240px;
  }

  @media only screen and (max-width: 800px) {
    & > img {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const ViewDetailsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const ViewDetailsContentItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ViewDetailsItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  .col-span-two {
    grid-column: 1 / span 2;
  }
`;

const ViewDetailsItemWithChips = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const ViewDetailsItemWithCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const ViewDetailsTitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const ViewDetailsItemOne = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ViewDetailsItemTwo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  .col-span-four {
    grid-column: 1 / span 4;
  }
  .pos-end-row-span {
    grid-column-start: 4;
    grid-row: 1 / span 2;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);

    .col-span-four {
      grid-column: 1 / span 2;
    }

    .pos-end-row-span {
      grid-column: 1 / span 2;
      grid-row: auto;
    }
  }
`;

export {
  ViewDetailsWrap,
  ViewDetailsContentWithImg,
  ViewDetailsContent,
  ViewDetailsContentItems,
  ViewDetailsItem,
  ViewDetailsItemWithChips,
  ViewDetailsItemWithCard,
  ViewDetailsTitleWithIcon,
  ViewDetailsItemOne,
  ViewDetailsItemTwo,
};
