import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const PasswordField = ({
  label,
  error = '',
  mode = false,
  id,
  name,
  value,
  onChange,
  iconOnClick,
  disabled = false,
  isHelperText = true,
  touched,
}) => {
  return (
    <FormControl variant="outlined">
      <InputLabel error={touched && Boolean(error)}>{label}</InputLabel>
      <OutlinedInput
        type={mode ? 'text' : 'password'}
        id={id}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={touched && Boolean(error)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={iconOnClick}
              edge="end"
              id="iconButtonId"
              name="iconButtonName"
            >
              {mode ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
        disabled={disabled}
      />
      {isHelperText ? (
        <FormHelperText error style={{ minHeight: '24px', marginLeft: '15px', fontSize: '0.80rem' }}>
          {touched && error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  mode: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  iconOnClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isHelperText: PropTypes.bool,
  touched: PropTypes.bool,
};
export default PasswordField;
