import { Flex } from '../../../../style/utils/Flex.styled';

export const LabelProps = ({
  className,
  label,
  id,
  required,
  icon,
}) => {
  return (
    <Flex>
      <label className={className} htmlFor={id}>
        {label}
      </label>
      {required ? icon : null}
    </Flex>
  );
};
