// Navbar Style

import styled from 'styled-components';

// Default Navbar Style
export const Navbar = styled.nav`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`;
