import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Alert,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {
  AuthContainer,
  AUthForm,
  AuthInputs,
  AuthItem,
  AuthTypo,
} from '../login/login.styled';
import { Img } from '../../../style/components/Graphics.styled';
import { Logo } from '../../../imgs/imgs';

import { addNewPassword, verifyPasswordLink } from '../../../api/apis';
import CardForMessage from '../../components/CardForMessage';
import showSnackbar from '../../components/message/showSnackbar';
import PasswordField from '../../components/form/formControl/PasswordField';
import Button from '../../components/form/formControl/Button';
import LoadingSpinner from '../../components/form/Loading/LoadingSpinner';

const NewPassWord = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [serverResponseMessage, setServerResponseMessage] = useState();
  const [searchParams] = useSearchParams();
  const [disableAll, setDisableAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function verifyLink() {
      const data = {
        key: searchParams.get('key'),
        flag: searchParams.get('flag'),
      };
      const responseData = await verifyPasswordLink(data);
      if (!responseData.success) {
        setServerResponseMessage(responseData.message);
        setDisableAll(true);
      }
    }
    verifyLink();
  }, []);

  return (
    <AuthContainer>
      <AuthItem bg>
        <Img src={Logo.ct_logo} alt="logo" />
      </AuthItem>
      <AuthItem>
        {disableAll ? (
          <CardForMessage
            blockSpacing={{
              maxWidth: 345,
              borderRadius: '2rem',
              padding: '0.50rem',
            }}
            customContentClass="center-the-snippet"
            typoVariant="body1"
            typoColor="text.secondary"
            typoTextAlign="justify"
            customActionClass="center-the-snippet"
            buttonVariant="outlined"
            buttonOnClick={() => {
              navigate('/login');
            }}
            icon={<UnsubscribeIcon fontSize="large" color="error" />}
            message={serverResponseMessage && serverResponseMessage}
          />
        ) : (
          <Formik
            initialValues={{ newpassword: '', confirmPassword: '' }}
            validationSchema={Yup.object({
              newpassword: Yup.string().required(
                'Please Add your New Password!',
              ),
              confirmPassword: Yup.string()
                .required('Please Add your Confirm Password!')
                .oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
            })}
            onSubmit={async (value) => {
              setLoading(true);
              const data = {
                newpassword: value.newpassword,
                key: searchParams.get('key'),
                flag: searchParams.get('flag'),
              };
              const responseData = await addNewPassword(data);
              if (responseData.success) {
                showSnackbar(responseData.message, {
                  variant: 'success',
                });
                navigate('/login');
              } else {
                setServerResponseMessage(responseData.message);
                showSnackbar(responseData.message, {
                  variant: 'error',
                });
              }
              setLoading(false);
            }}
          >
            {(props) => {
              const {
                values, handleChange, touched, errors,
              } = props;
              return (
                // <SlideLeftAnimation>
                <AUthForm>
                  <AuthTypo>
                    <Typography className="login-title">
                      Create New Password
                    </Typography>
                    <Typography className="login-subtitle">
                      Change Password
                    </Typography>
                  </AuthTypo>
                  <AuthInputs>
                    {serverResponseMessage && (
                      <Alert severity="error">
                        {serverResponseMessage.somthingIsWronng
                          ? serverResponseMessage.somthingIsWronng
                          : serverResponseMessage}
                      </Alert>
                    )}
                    <PasswordField
                      label="Password"
                      error={errors.newpassword}
                      mode={showPassword}
                      touched={errors.newpassword}
                      id="outlined-adornment-newpassword"
                      name="newpassword"
                      value={values.newpassword}
                      onChange={handleChange}
                      iconOnClick={handleClickShowPassword}
                      variant="Outlined"
                    />
                    <PasswordField
                      label="Confirm Password"
                      mode={showConfirmPassword}
                      id="confirmPasswordId"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      iconOnClick={handleClickShowConfirmPassword}
                      error={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      disabled={disableAll}
                    />
                  </AuthInputs>
                  <Button
                    id="createPassword"
                    name="createPassword"
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={disableAll || loading}
                  >
                    {loading && <LoadingSpinner />}
                    Create Password
                  </Button>
                </AUthForm>
                // </SlideLeftAnimation>
              );
            }}
          </Formik>
        )}
      </AuthItem>
    </AuthContainer>
  );
};
export default NewPassWord;
