import React from 'react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';
import TagIcon from '@mui/icons-material/Tag';
import { ColSpan, Grid } from '../../../../style/utils/Grid.styled';
import { ViewChip } from '../controls/ViewChip';
import { ViewDividerWithTitle } from '../controls/ViewDividerWithTitle';
import { ViewField } from '../controls/ViewField';
import {
  ViewDetailsContentItems,
  ViewDetailsItemTwo,
  ViewDetailsItemWithCard,
  ViewDetailsItemWithChips,
  ViewDetailsTitleWithIcon,
  ViewDetailsWrap,
} from '../../../../style/components/ApplicationView.styled';

export const ProfessionalDetailsView = ({ details }) => {
  const {
    skillSet,
    personalDetails,
    experianceDetails,
    portfolioDetails,
  } = details;

  return (
    <ViewDetailsWrap>
      {/* 1)  skill sets */}
      {!isEmpty(skillSet) ? (
        <ViewDetailsItemWithChips>
          <ViewChip name={'Skills'} value={skillSet.skills} />
          <ViewChip name={'Tools'} value={skillSet.tools} />
        </ViewDetailsItemWithChips>
      ) : null}

      {/* 2) experiance details */}
      {!isEmpty(experianceDetails) &&
      !isEmpty(experianceDetails[0].companyName) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Experiance Details'} />
          <ViewField
            name={'Experiance Status'}
            value={personalDetails.experianceStatus}
          />
          {experianceDetails.map((exp, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Experiance ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Company name'}
                    value={experianceDetails[index].companyName}
                  />
                  <ViewField
                    name={'Job title'}
                    value={experianceDetails[index].jobTitle}
                  />
                  <ViewField
                    name={'City'}
                    value={experianceDetails[index].city}
                  />
                  <ViewField
                    name={'Year of Experiance'}
                    value={experianceDetails[index].yearOfExperiance}
                  />
                  <ViewField
                    name={'Start date'}
                    value={moment(experianceDetails[index].joinningDate).format(
                      'LL',
                    )}
                  />
                  <ViewField
                    name={'End Date'}
                    value={moment(experianceDetails[index].tillDate).format(
                      'LL',
                    )}
                  />
                  <ViewChip
                    name={'Projects'}
                    value={experianceDetails[index].projects}
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
        ) : null}

      {/* 3) portfolio Details */}
      {!isEmpty(portfolioDetails) && !isEmpty(portfolioDetails[0].title) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Portfolio Details'} />
          <ViewDetailsItemWithCard>
            {portfolioDetails.map((prtFl, index) => {
              return (
                <Card variant="outlined" key={index + 1}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {portfolioDetails[index].title}
                    </Typography>
                    <Typography variant="body">
                      {portfolioDetails[index].description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      href={portfolioDetails[index].link}
                      endIcon={<OpenInNewRoundedIcon />}
                      target="_blank"
                      // variant="outlined"
                    >
                      VISIT
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </ViewDetailsItemWithCard>
        </ViewDetailsContentItems>
      ) : null}
    </ViewDetailsWrap>
  );
};
