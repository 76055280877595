import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Chip,
  Divider,
} from '@mui/material';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { pick } from 'lodash';
import { MyContext } from '../../context/Context';
import { Section } from '../../../style/layout/Section.styled';
import {
  AllCardWrap,
  CardActionFooter,
  CardContainer,
  CardHead,
  CardTopbar,
} from '../../../style/components/card.styled';

const Home = () => {
  const {
    viewOnBoardApp,
    proceedOnBoard,
    appDetails,
    applicationDets,
  } = useContext(MyContext);

  if (appDetails !== '' && appDetails !== undefined) {
    localStorage.setItem('applicationId', appDetails.applicationId);
    localStorage.setItem('candidateId', appDetails.candidateId);
  }

  useEffect(() => {
    appDetails();
  }, []);

  const applicationStatusChips = (props) => {
    if (props === 'created') {
      return <Chip icon={<FactCheckOutlinedIcon />} label={props} />;
    }
    if (props === 'started') {
      return <Chip icon={<StartOutlinedIcon />} label={props} />;
    }

    if (props === 'submitted') {
      return <Chip icon={<FactCheckOutlinedIcon />} label={props} />;
    }

    if (props === 'approved') {
      return <Chip icon={<VerifiedIcon />} label={props} />;
    }

    if (props === 'rejected') {
      return <Chip icon={<CancelOutlinedIcon />} label={props} />;
    }

    if (props === 'archived') {
      return <Chip icon={<ArchiveOutlinedIcon />} label={props} />;
    }

    // if (props === 'delivered') {
    //   return <Chip icon={<DoneAllIcon />} label={props.toUpperCase()} />;
    // }
    return null;
  };

  const applicationStatusButtons = (appStatus) => {
    if (appStatus === 'created' || appStatus === 'delivered') {
      return (
        <Button size="small" onClick={() => proceedOnBoard(appStatus)}>
          Start onboarding
        </Button>
      );
    }
    if (appStatus === 'started') {
      return (
        <Button size="small" onClick={() => proceedOnBoard(appStatus)}>
          Continue
        </Button>
      );
    }
    if (
      appStatus === 'approved' ||
      appStatus === 'rejected' ||
      appStatus === 'submitted'
    ) {
      return (
        <Button
          size="small"
          onClick={() => viewOnBoardApp(pick(appDetails, ['applicationId', 'candidateId']))}
        >
          View
        </Button>
      );
    }
    return null;
  };

  const alertMessage = (props) => {
    if (props === 'rejected') {
      return (
        <Alert severity="error" mb="1rem">
          <AlertTitle>Error</AlertTitle>
          This is an error alert — check it out!
        </Alert>
      );
    }
    return null;
  };

  return (
    <Section>
      {alertMessage()}
      <AllCardWrap>
        <CardContainer variant="outlined" brdbtm="true" status={'created'}>
          <CardTopbar>{applicationDets.createdAt}</CardTopbar>
          <CardHead
            avatar={<Avatar>CT</Avatar>}
            title={`${applicationDets.firstname} ${applicationDets.lastname}`}
            subheader={applicationDets.applicationTag}
          />
          <Divider />
          <CardActionFooter>
            {applicationStatusChips(applicationDets.applicationStatus)}
            {applicationStatusButtons(applicationDets.applicationStatus)}
          </CardActionFooter>
        </CardContainer>
      </AllCardWrap>
    </Section>
  );
};

export default Home;
