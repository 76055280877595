/* eslint-disable */

import React from 'react';

function ErrorMessage(props) {
  const { message } = props;
  const style = {
    minHeight: '24px',
    fontSize: '0.8rem',
    marginTop: '-9px',
    marginBottom: '-10px',
    marginLeft: '8px',
    color: '#d32f2f',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
  }
  return (
    <div
      style={style}
    >
      {message}
    </div>
  )
}

export default ErrorMessage;