import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Typography,
} from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {
  AuthContainer,
  AUthForm,
  AuthInputs,
  AuthItem,
  AuthTypo,
} from '../login/login.styled';
import { Img } from '../../../style/components/Graphics.styled';
import { Logo } from '../../../imgs/imgs';
import { chnagePasswordRequestApi } from '../../../api/apis';
import CardForMessage from '../../components/CardForMessage';
import showSnackbar from '../../components/message/showSnackbar';
import TextField from '../../components/form/formControl/TextField';
import Button from '../../components/form/formControl/Button';
import LoadingSpinner from '../../components/form/Loading/LoadingSpinner';

const EmailScreen = () => {
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState({});
  const [pageSwitcher, setPageSwitcher] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <AuthContainer>
      <AuthItem bg>
        <Img src={Logo.ct_logo} alt="logo" />
      </AuthItem>
      <AuthItem>
        {pageSwitcher ? (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string('')
                .required('Please provide your email address !')
                .email('Enter a valid email address !'),
            })}
            onSubmit={async (value) => {
              setLoading(true);
              const responseData = await chnagePasswordRequestApi(value);
              if (responseData.success) {
                setPageSwitcher(false);
              } else {
                showSnackbar(responseData?.message?.errorMessage, {
                  variant: 'error',
                });
              }
              setServerMessage({
                flage: responseData.success,
                serverResponseMessage: responseData.message,
                status: responseData.status,
              });
              setLoading(false);
            }}
          >
            {(props) => {
              const {
                values, handleChange, touched, errors,
              } = props;
              return (
                <AUthForm>
                  <AuthTypo>
                    <Typography className="login-title">
                      Reset Your Password
                    </Typography>
                    <Typography className="login-subtitle">
                      Enter your email to reset your password
                    </Typography>
                  </AuthTypo>
                  <AuthInputs>
                    {loading && (
                      <Alert severity="info">
                        An email is on its way to you. Kindly await its
                        delivery.
                      </Alert>
                    )}
                    <TextField
                      id="email"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      errors={errors.email}
                      touched={touched.email}
                      disabled={loading}
                      variant="outlined"
                    />
                  </AuthInputs>
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={loading}
                  >
                    {loading && <LoadingSpinner />}
                    Send Request
                  </Button>
                </AUthForm>
              );
            }}
          </Formik>
        ) : (
          <CardForMessage
            blockSpacing={{ maxWidth: 345, borderRadius: '2rem', padding: '0.50rem' }}
            customContentClass="center-the-snippet"
            typoVariant="body1"
            typoColor="text.secondary"
            typoTextAlign="justify"
            customActionClass="center-the-snippet"
            buttonVariant="outlined"
            buttonOnClick={() => {
              navigate('/login');
            }}
            icon={<MarkEmailReadIcon fontSize="large" color="success" />}
            message={serverMessage.flage && serverMessage.serverResponseMessage}
          />
        )}
      </AuthItem>
    </AuthContainer>
  );
};

export default EmailScreen;
