/* eslint-disable */
import { TextField as MUITextField, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';

const TextField = (props) => {
  const {
    id = '',
    type = 'text',
    placeholder = '',
    label = '',
    name = '',
    value = '',
    onChange = () => { },
    variant = 'standard',
    errors = '',
    touched,
    size = 'medium',
    serverException = '',
    className,
    disabled = false,
    onBlur = () => { },
    isHelperText = true,
    multiline = false,
    maxRows,
  } = props;

  return (
    <div>
      <MUITextField
        variant={variant}
        id={id}
        type={type}
        placeholder={placeholder}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={(touched && Boolean(errors)) || Boolean(serverException)}
        size={size}
        className={className}
        disabled={disabled}
        multiline={multiline}
        maxRows={maxRows}
        fullWidth
      />
      {
        isHelperText && (
          <FormHelperText error style={{ minHeight: '24px', marginLeft: '15px', fontSize: '0.80rem' }}>
            {(touched && errors) || serverException}
          </FormHelperText>
        )
      }
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  size: PropTypes.string,
  serverException: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  isHelperText: PropTypes.bool,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  fullWidth: PropTypes.bool,
};


export default TextField;
