import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context/Context';
import {
  ErrorContent,
  ErrorImg,
  ErrorTypo,
  ErrorWrap,
  ErrorStatus,
  ErrorMessage,
  ErrorCode,
} from './error.style';

import noConnectionImg from '../../../imgs/svg/no-connection.svg';

// module.exports = {
//   apps: [
//     {
//       name: 'onboard-admin',
//       script: '/ct-onboard/admin-be/bin/www',

//       instances: 1,
//       watch: true,
//       ignore_watch: ['logs'],
//       autorestart: true,
//       max_memory_restart: '1G',
//     },
//     {
//       name: 'onboard-candidate',
//       script: '/ct-onboard/candidate-be/bin/www',

//       instances: 1,
//       watch: true,
//       ignore_watch: ['logs'],
//       autorestart: true,
//       max_memory_restart: '1G',
//     },
//   ],
// };

const handleErrorImg = (props) => {
  return <ErrorImg src={noConnectionImg} alt="No Connection" />;
};

const Error = () => {
  const navigate = useNavigate();
  const { error } = useContext(MyContext);
  const {
    isError,
    status,
    code,
    message,
  } = error;

  useEffect(() => {
    !isError && navigate('/');
  }, []);

  return (
    <ErrorWrap>
      <ErrorContent>
        {handleErrorImg(error)}
        <ErrorTypo>
          <ErrorStatus>{status}</ErrorStatus>
          <ErrorCode>{code}</ErrorCode>
          <ErrorMessage>{message}</ErrorMessage>
        </ErrorTypo>
      </ErrorContent>
    </ErrorWrap>
  );
};

export default Error;
