import * as Yup from 'yup';

const otherDetailsValidationSchema = Yup.object({
  bankDetails: Yup.object().shape({
    bankName: Yup.string().required('Bank name is required.'),
    branch: Yup.string().required('Branch name is required.'),
    accountHolder: Yup.string().required('Account holder name is required.'),
    accountNo: Yup.string().nullable().required('Account number is required.'),
    ifscNo: Yup.string().nullable().required('IFSC code is required.'),
    attachment: Yup.mixed()
      .test({
        message: 'Passbook or cancelled cheque images is required',
        test: (file, context) => {
          if (
            !context.from[context.from.length - 1].value.bankDetails.document &&
            !file
          ) {
            return false;
          }
          return true;
        },
      })
      .test({
        message: 'You can Only Upload PNG/JPG/JPEG',
        test: (file, context) => {
          if (!file) {
            return true;
          }
          if (file) {
            return !(file && !file.name.match(/\.(jpg|jpeg|png)$/));
          }
        },
      })
      .test({
        message: 'More than 2MB size image is not allowed',
        test: (file, context) => {
          if (!file) {
            return true;
          }
          if (file) {
            return !(file && (file.size / (1024 * 1024)).toFixed(2) > 5);
          }
        },
      }),
  }),
  educationDetails: Yup.array(
    Yup.object({
      category: Yup.string().required('Category selection is required.'),
      degreeName: Yup.string().required('Degree name is required.'),
      institute: Yup.string().required('Institute name is required.'),
      boardOrUniversity: Yup.string().required('Board or University name is required.'),
      gpaOrPercentage: Yup.string().required('GPA or Percentage is required.'),
      passingYear: Yup.date()
        .required('Year of Passing is required.')
        .nullable(),
    }),
  ),
  emergencyContactDetails: Yup.array(
    Yup.object({
      fullname: Yup.string().required('Full name is required.'),
      contactNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Contact number must be exactly 10 digits.')
        .required('Contact number is required.'),
      relation: Yup.string().required('Relation selection is required.'),
      city: Yup.string().required('City is required.'),
      note: Yup.string().required('Note is required.'),
    }),
  ),
});
export default otherDetailsValidationSchema;
