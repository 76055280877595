import { Dialog, Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
  AvatarImg,
  MediumImg,
  PopupImage,
} from '../../../style/components/Graphics.styled';

export const ImagePopup = (props) => {
  const [popup, setPopup] = useState(false);
  const {
    variant,
    img,
    alt,
    skeletonVariant,
    skeletonRatio,
    css,
  } = props;
  const imgVariant = () => {
    if (variant === 'avatar') {
      return (
        <AvatarImg
          className={css}
          src={img}
          alt={alt}
          onClick={() => setPopup(true)}
        />
      );
    }
    return <MediumImg className={css} src={img} alt={alt} onClick={() => setPopup(true)} />;
  };

  return !isEmpty(img) ? (
    <>
      {imgVariant()}
      <Dialog open={popup} onClose={() => setPopup(false)}>
        <PopupImage src={img} alt={alt} />
      </Dialog>
    </>
  ) : (
    <Skeleton
      className={css}
      variant={skeletonVariant}
      width={skeletonRatio}
      height={skeletonRatio}
    />
  );
};
