import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyContext } from '../../context/Context';
import PersonalDetailsView from './accordion_details/PersonalDetailsView';
import { ProfessionalDetailsView } from './accordion_details/ProfessionalDetailsView';
import { OtherDetailsView } from './accordion_details/OtherDetailsView';

const ExaminingApplication = () => {
  const {
    examiningCandidateOnBoardApp,
    personalDets,
    professionalDets,
    otherDets,
  } = useContext(MyContext);
  const [params] = useSearchParams();
  const getAppKey = params.get('applicationKey');

  const accordionDetailsSection = [
    {
      id: 'personal-details',
      defExpanded: true,
      title: 'Personal Details',
      component: <PersonalDetailsView details={personalDets} />,
    },
    {
      id: 'professional-details',
      defExpanded: true,
      title: 'Professional Details',
      component: <ProfessionalDetailsView details={professionalDets} />,
    },
    {
      id: 'other-details',
      defExpanded: true,
      title: 'Other Details',
      component: <OtherDetailsView details={otherDets} />,
    },
  ];

  useEffect(() => {
    examiningCandidateOnBoardApp({ applicationKey: getAppKey });
  }, []);

  return (
    <section>
      {accordionDetailsSection.map((item, index) => {
        const {
          id,
          defExpanded,
          title,
          component,
        } = item;
        return (
          <Accordion defaultExpanded={defExpanded} key={id + index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={id}
            >
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{component}</AccordionDetails>
          </Accordion>
        );
      })}
    </section>
  );
};

export default ExaminingApplication;
