import {
  Typography,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { update } from 'lodash';
import {
  FormWrap,
  FromContentTwo,
} from '../../../../style/components/form/StyledForm.styled';
import { Grid, ColSpan } from '../../../../style/utils/Grid.styled';
import CustomTextField from '../../../components/form/formControl/TextField';

const AddressDetailsForm = ({ formController }) => {
  const {
    values,
    handleChange,
    touched,
    errors,
  } = formController;

  const isSameAsPermanent = values.sameAsPermanentAddress;

  if (isSameAsPermanent) {
    update(values, 'communicationStreet', () => values.permanentStreet);
    update(values, 'communicationCity', () => values.permanentCity);
    update(values, 'communicationPincode', () => values.permanentPincode);
    update(values, 'communicationState', () => values.permanentState);
    update(values, 'communicationCountry', () => values.permanentCountry);
  }

  return (
    <FormWrap>
      <Typography variant="h6">Address Details</Typography>
      <Grid gap={'2rem'}>
        {/* Permanent Address */}
        <FromContentTwo>
          <ColSpan className="col-span-two" ai={'center'}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              Permanent address
            </Divider>
          </ColSpan>
          <CustomTextField
            className="col-span-two"
            label="Street"
            name="permanentStreet"
            value={values.permanentStreet}
            onChange={handleChange}
            touched={touched.permanentStreet}
            errors={errors.permanentStreet}
            multiline
            maxRows={2}
            fullWidth
            variant="outlined"
          />
          <CustomTextField
            label="City"
            name="permanentCity"
            value={values.permanentCity}
            onChange={handleChange}
            touched={touched.permanentCity}
            errors={errors.permanentCity}
            variant="outlined"
          />
          <CustomTextField
            type="number"
            label="Pincode"
            name="permanentPincode"
            value={values.permanentPincode}
            onChange={handleChange}
            touched={touched.permanentPincode}
            errors={errors.permanentPincode}
            variant="outlined"
          />
          <CustomTextField
            label="State"
            name="permanentState"
            value={values.permanentState}
            onChange={handleChange}
            touched={touched.permanentState}
            errors={errors.permanentState}
            variant="outlined"
          />
          <CustomTextField
            label="Country"
            name="permanentCountry"
            value={values.permanentCountry}
            onChange={handleChange}
            errors={errors.permanentCountry}
            touched={touched.permanentCity}
            variant="outlined"
          />
        </FromContentTwo>
        {/* Communication Address */}
        <FromContentTwo>
          <ColSpan className="col-span-two" ai={'center'}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              Communication address
            </Divider>
          </ColSpan>
          <FormControlLabel
            className="col-span-two"
            control={<Switch />}
            label="Same as Permanent Address"
            name="sameAsPermanentAddress"
            checked={values.sameAsPermanentAddress}
            onChange={handleChange}
          />
          <CustomTextField
            className="col-span-two"
            disabled={isSameAsPermanent}
            label="Street"
            name="communicationStreet"
            value={values.communicationStreet}
            onChange={handleChange}
            multiline
            maxRows={2}
            fullWidth
            variant="outlined"
          />
          <CustomTextField
            disabled={isSameAsPermanent}
            label="City"
            name="communicationCity"
            value={values.communicationCity}
            onChange={handleChange}
            variant="outlined"
          />
          <CustomTextField
            disabled={isSameAsPermanent}
            type="number"
            label="Pincode"
            name="communicationPincode"
            value={values.communicationPincode}
            onChange={handleChange}
            variant="outlined"
          />
          <CustomTextField
            disabled={isSameAsPermanent}
            label="State"
            name="communicationState"
            value={values.communicationState}
            onChange={handleChange}
            variant="outlined"
          />
          <CustomTextField
            disabled={isSameAsPermanent}
            label="Country"
            name="communicationCountry"
            value={values.communicationCountry}
            onChange={handleChange}
            variant="outlined"
          />
        </FromContentTwo>
      </Grid>
    </FormWrap>
  );
};
export default AddressDetailsForm;
