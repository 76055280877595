import { Card, CardActions, CardHeader } from '@mui/material';
import styled from 'styled-components';

const AllCardWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const CardContainer = styled(Card)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  ${({ brdbtm, status, theme }) => (
    brdbtm
      ? `border-left: 0.3rem solid ${status === 'rejected' ? 'red' : theme.color.primary} !important`
      : null
  )}
`;

const CardTopbar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    padding: 0;
  }
`;

const CardHead = styled(CardHeader)`
  padding: 0 !important;
`;

const CardActionFooter = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
`;

export {
  AllCardWrap,
  CardContainer,
  CardTopbar,
  CardHead,
  CardActionFooter,
};
