// app theme style

const theme = {
  // Default  Screen Resolution
  resolution: {
    width: '1280px',
  },

  // Default  Colors for app
  color: {
    // New
    def: '#F5F5F5',
    lightes: '#FFFFFF',
    primary: '#007DB8',
    // old
    primaryLight: '#e6f7ff',
    primaryLightest: '#f5fcff',
    darkest: '#191919',
    lightDark: '#808080',
    light: '#f8f8f8',
    errorLight: '#FDCBCC',
    errorDark: '#BF382A',
    disable: '#a9b6bc',
    // #ec6861 red color
  },

  // Default  Font size for app
  fontSize: {
    h1: '3rem',
    h2: '2.25rem',
    h3: '1.75rem',
    h4: '1.25rem',
    h5: '1rem',
    h6: '0.75rem',
    body: '1rem',
  },

  // Default  Font Weight for app
  fontWeight: {
    w1: '300',
    w2: '400',
    w3: '600',
    w4: '700',
    w5: '900',
  },

  // Default Border Radius
  radius: '5px',

  // Default shadow
  boxShadow: '0px 0px 4px 2px rgba(25,25,25,0.11);',

  // Default Transations
  zeroTwo: '0.35s ease-in-out',
};
export default theme;
