// Dynamik Grid component

import styled from 'styled-components';

export const Grid = styled.div`
  position: ${({ pos }) => pos};
  width: ${({ wd }) => wd};
  height: auto;
  display: grid;
  gap: ${({ gap }) => gap};
  grid-template-columns: ${({ col }) => col};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  grid-column: ${({ colSpan }) => colSpan};
  background-color: ${({ bg }) => bg};
  padding: ${({ pd }) => pd};
  border-radius: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  margin-block: ${({ mgBlock }) => mgBlock};

  @media only screen and (max-width: 800px) {
    .mq-grid-to-flex {
      background-color: aqua;
    }
  }
`;
/* grid-template-columns: ${({ col }) => col}; */
export const GridWrapper = styled.div`
  display: grid;
  gap: 1rem;
`;

export const GridContainer = styled.div`
  display: grid;
  position: absolute;
`;

export const Absolute = styled.div`
  position: absolute;
  inset: 50% 0 0 -50px;
  z-index: 99;
`;

export const ColSpan = styled.span`
  display: flex;
  gap: 0.25rem;
  align-items: ${({ ai }) => ai};
  grid-column: ${({ colSpan }) => colSpan};
`;

export const RowSpan = styled.span`
  grid-row: ${({ rowSpan }) => rowSpan};
`;

export const ColRowSpan = styled.span`
  grid-column: ${({ colSpan }) => colSpan};
  grid-row: ${({ rowSpan }) => rowSpan};
`;
