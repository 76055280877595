import { useContext, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  List,
  ListItemText,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { filter, isEmpty, startCase } from 'lodash';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyContext } from '../../context/Context';
import PersonalDetailsView from './accordion_details/PersonalDetailsView';
import { ProfessionalDetailsView } from './accordion_details/ProfessionalDetailsView';
import { OtherDetailsView } from './accordion_details/OtherDetailsView';
import { adminCandidateViewApi } from '../../../api/apis';

const Application = () => {
  const [appKey] = useSearchParams();
  const navigate = useNavigate();
  const getAppKey = appKey.get('applicationKey');

  const {
    applicationDets,
    personalDets,
    setPersonalDets,
    professionalDets,
    setProfessionalDets,
    otherDets,
    setOtherDets,
    appDetails,
    appLogApi,
    appLog,
    getPersonalDetails,
    getProfessionalDetails,
    getCandidateOtherDetails,
  } = useContext(MyContext);

  const rejectData = filter(appLog, ['status', 'rejected']);

  const getAllDetails = () => {
    appDetails();
    appLogApi();
    getPersonalDetails();
    getProfessionalDetails();
    getCandidateOtherDetails();
  };

  const verifyAppKeyAndGetAppDets = async (props) => {
    const verifyAppKeyApi = await adminCandidateViewApi(props);
    const { data, success } = verifyAppKeyApi;
    setPersonalDets(data.candidateDetails);
    setProfessionalDets(data.professionalDetails);
    setOtherDets(data.otherDetails);
    if (success) navigate('/application');
    getOnBoardAppDets(data);
  };

  useEffect(() => {
    if (!isEmpty(getAppKey)) {
      verifyAppKeyAndGetAppDets({ applicationKey: getAppKey });
    }
    getAllDetails();
  }, []);

  const accordionDetailsSection = [
    {
      id: 'personal-details',
      defExpanded: true,
      title: 'Personal Details',
      component: <PersonalDetailsView details={personalDets} />,
    },
    {
      id: 'professional-details',
      defExpanded: true,
      title: 'Professional Details',
      component: <ProfessionalDetailsView details={professionalDets} />,
    },
    {
      id: 'other-details',
      defExpanded: true,
      title: 'Other Details',
      component: <OtherDetailsView details={otherDets} />,
    },
  ];

  return (
    <section>
      {applicationDets.applicationStatus === 'rejected' ? (
        <Alert severity="error">
          <AlertTitle>{startCase(applicationDets.applicationStatus)}</AlertTitle>
          {rejectData.map((item) => (
            <List key={Math.random() * 2}>
              <ListItemText primary={item.note} />
            </List>
          ))}
        </Alert>
      ) : null}
      <div>
        {accordionDetailsSection.map((item, index) => {
          const {
            id,
            defExpanded,
            title,
            component,
          } = item;
          return (
            <Accordion defaultExpanded={defExpanded} key={id + index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={id}
              >
                <Typography>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>{component}</AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </section>
  );
};

export default Application;
