import styled from 'styled-components';
import { ErrorFieldProps } from '../../../template/components/form/formControl/FromError';

// Form Error Style
export const ErrorFiled = styled(ErrorFieldProps)`
  width: ${({ wd }) => wd};
  background-color: ${({ theme }) => theme.color.errorLight};
  color: ${({ theme }) => theme.color.errorDark};
  border-radius: ${({ theme }) => theme.radius};
  padding: 0.2rem 0.4rem;
  font-size: 14px;
  font-weight: 600;
`;
