import * as Yup from 'yup';
import 'yup-phone';

const personalDetailsValidationSchema = Yup.object({
  firstname: Yup.string().required('Please enter your first name.'),
  lastname: Yup.string().required('Please enter your last name.'),
  email: Yup.string().email('Please enter a valid email address.').required('Email is required.'),
  contactNo: Yup.string()
    .min(10, 'Contact number should be 10 digits long.')
    .max(10, 'Contact number should be 10 digits long.')
    .required('Contact number is required.'),
  birthDate: Yup.date().required('Please enter your date of birth.').nullable(),
  age: Yup.string().required('Please enter your age.'),
  gender: Yup.string().required('Please select your gender.'),
  nationality: Yup.string().required('Please enter your nationality.'),
  permanentStreet: Yup.string().required('Please enter your street address.'),
  permanentCity: Yup.string().required('Please enter your  city.'),
  permanentPincode: Yup.string().required('Please enter your pincode.'),
  permanentState: Yup.string().required('Please enter your  state.'),
  permanentCountry: Yup.string().required('Please enter your country.'),
  avatarAttachment: Yup.mixed()
    .test({
      message: 'Only PNG, JPG, or JPEG files are allowed.',
      test: (fileHolder) => {
        if (!fileHolder) {
          return true;
        }
        if (fileHolder) {
          return !(fileHolder && !fileHolder.name.match(/\.(jpg|jpeg|png)$/));
        }
      },
    })
    .test({
      message: 'More than 2MB size image is not allowed',
      test: (fileHolder) => {
        if (!fileHolder) {
          return true;
        }
        return !(fileHolder && (fileHolder.size / (1024 * 1024)).toFixed(2) > 5);
      },
    }),
});

export default personalDetailsValidationSchema;
